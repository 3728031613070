import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  carouselImg = [
    './galerie/bg-activites-agro-2.jpg',
    './galerie/bg-activites-emergence-agro.jpg',
    './galerie/Calendrier_chevalet.jpg',
    './galerie/bg-activites-emergence-agro.jpg'
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
