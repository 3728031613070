import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Slider } from '../_models/slider';


@Injectable({
  providedIn: 'root'
})

export class SliderService {
  constructor(private http: HttpClient) {
  }

  getSlidersDisplayed() {
    return this.http.get<Slider[]>(`${environment.api}/api/sliders/displayed`);
  }

}
