import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactService } from 'src/app/_services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  infoBandeau = {
    'image' : './../../assets/bandeau-etablissement.png',
    'titre' : 'Contact',
    'breadcrumb' : 'ACCUEIL > Contact'
  }
  submitted = false;
  contactForm : any = [];


  constructor(private http: HttpClient, private contactService : ContactService, readonly snackBar: MatSnackBar, private formBuilder: FormBuilder) {
    this.contactForm = this.formBuilder.group({
      nom: new FormControl('', [
        Validators.required
      ]),
      prenom: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required
      ]),
      telephone: new FormControl('', [
        Validators.required
      ]),
      message: new FormControl('', [
        Validators.required
      ]),
      check: new FormControl('', [
        Validators.required
      ])
    });
  }

  ngOnInit(): void {
  }
  get f() { return this.contactForm.controls; }


  onFormSubmit(contactForm:NgForm){
    this.submitted = true;

    if (this.contactForm.invalid) {
      return;
    }
    console.log(contactForm)
    const body = {
      "nom": contactForm.value['nom'],
      "prenom": contactForm.value['prenom'],
      "email": contactForm.value['email'],
      "telephone": contactForm.value['telephone'],
      "message": contactForm.value['message']
    }
    this.contactService.contact(body).subscribe(
      (data) => {
        this.snackBar.open('Demande de contact envoyé ! Nous vous contacterons prochainement.', '', {
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: ['snackbar-success']
        });
        this.resetForm(contactForm);
        this.submitted = false;
      }
    );
    }


  resetForm(contactForm:NgForm){
    contactForm.reset();
  }

}
