<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image" [titreBandeau]="infoBandeau.titre" ></app-bandeau-page>
<app-donation-onglet></app-donation-onglet>
<section class="rejoindre-details__section">
  <div class="container-fluid g-0">
    <div class="row justify-content-center">
      <div class="col-xxl-6 col-10">
        <a [routerLink]="['/nous-rejoindre']" class="btn btn-rejoindre">Retour à la page des offres</a>
        <div class="date">Posté le {{ offre.date.date | date : 'd LLLL, y' : '' : 'fr' }}<span class="pills_urgent" *ngIf="offre.urgent">Urgent</span><span class="pills_cdi">{{ offre.categorie }}</span></div>
        <div class="texte" [innerHTML]="offre.description">
          {{ offre.description }}
        </div>
        <div class="formulaire-container">
          <form [formGroup]="postulationForm" (ngSubmit)="onSubmit()">
          <div class="row justify-content-lg-between justify-content-center g-2">
            <h4 class="text-center">Postuler à l'offre</h4>
            <div class="col-md-6">
              <div class="form-floating">
                <input type="text" class="form-control" formControlName="nom" id="floatingNom" placeholder="nom" [ngClass]="{ 'is-invalid': submitted && f.nom.errors }">
                <label for="floatingNom">Nom: *</label>
                <div class="invalid-feedback">
                  Vous devez renseigner votre nom.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating">
                <input type="email" class="form-control" formControlName="prenom" id="floatingPrenom" placeholder="prenom" [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }">
                <label for="floatingPrenom">Prénom: *</label>
                <div class="invalid-feedback">
                  Vous devez renseigner votre prénom.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating">
                <input type="email" class="form-control" id="floatingEmail" formControlName="email" placeholder="mail" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                <label for="floatingEmail">Email: *</label>
                <div class="invalid-feedback">
                  Vous devez renseigner votre email.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating">
                <input type="tel" class="form-control" id="floatingTel" formControlName="telephone" placeholder="tel" [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }">
                <label for="floatingTel">Téléphone: *</label>
                <div class="invalid-feedback">
                  Vous devez renseigner votre téléphone.
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3 mt-4">
              <label for="formFileCV" class="form-label">CV (format pdf - obligatoire)*</label>
              <input class="form-control" type="file" id="formFileCV" formControlName="cv" (change)="handleFileInputCV($event)" [ngClass]="{ 'is-invalid': submitted && f.cv.errors }">
              <div class="invalid-feedback">
                Vous devez renseigner votre nom.
              </div>
            </div>
            <div class="col-md-6 mb-3 mt-4">
              <label for="formFileLettre" class="form-label" >Lettre de motivation (format pdf)</label>
              <input class="form-control" formControlName="lettre" type="file" id="formFileLettre" (change)="handleFileInputLettre($event)">
            </div>

            <div class="form-check">
              <input class="form-check-input" formControlName="check" type="checkbox" id="gridCheck" [ngClass]="{ 'is-invalid': submitted && f.check.errors }">
              <label class="form-check-label" for="gridCheck">
                En soumettant ce formulaire, j’accepte que les informations saisies dans ce formulaire soient utilisées, exploitées, traitées pour permettre de me recontacter, pour m’envoyer des informations, dans le cadre de la relation commerciale qui découle de cette demande. <a [routerLink]="['/politique-de-confidentialite']">En savoir plus</a>
              </label>
              <div class="invalid-feedback">
                Vous devez cocher la case pour valider.
              </div>
            </div>
            <p class="text-informations">Ces informations sont destinées à l'Association Le Patis Fraux. En application des articles 27 et 34 de la loi « Informatique et libertés » du 6 janvier 1978, vous disposez d’un droit de modification ou de suppression des données vous concernant. Ce droit peut être exercé par courrier électronique en adressant votre demande à accueil@patis-fraux.fr</p>
          </div>
          <button class="btn btn-rejoindre" type="submit">Je dépose mon CV</button>
        </form>
        </div>
      </div>
      <div class="col-xxl-2 col-10">
        <div class="ref-container">
          <p>
            <b>Référence:</b>
            <br>{{ offre.reference }}

            <br><b>Contrat:</b>
            <br>{{ offre.categorie }}

            <br><b>Date:</b>
            <br>{{ offre.date.date | date : 'dd/MM/y' : '' : 'fr' }}
          </p>
        </div>
        <div class="spontanee-container">
          <h3>Candidature spontanée</h3>
          <p>Vous souhaitez rejoindre nos équipes mais vous n’avez pas trouvé l’offre qui vous correspond ? Envoyez votre candidature spontanée en déposant votre CV.</p>
          <a (click)="openCandidatureSpotanee()" class="btn btn-rejoindre">Je dépose mon CV</a>
        </div>
      </div>
    </div>
  </div>
</section>
