import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actu } from '../_models/actu';
import { ActuService } from '../_services/actu.service';
import { MetadataServiceService } from '../_services/metadata-service.service';

@Component({
  selector: 'app-actualites-detail',
  templateUrl: './actualites-detail.component.html',
  styleUrls: ['./actualites-detail.component.scss']
})
export class ActualitesDetailComponent implements OnInit {

  infoBandeau = {
    'image' : './../../assets/bandeau-etablissement.png',
    'titre' : '',
    'breadcrumb' : 'ACCUEIL > Actualités'
  }

  actualite : Actu = new Actu();
  lastActualites : Actu[] = [];
  page : string = "";

  constructor(private actualiteService: ActuService, private route: ActivatedRoute, private router: Router, private metadataService: MetadataServiceService) {
    this.actualiteService.getBySlug(this.route.snapshot.params.slug).subscribe(
      (actualite) => {
        this.actualite = actualite;
        this.infoBandeau.titre = this.actualite.titre;
        this.page = '<style>'+ actualite.style +'</style>' + actualite.contenu;
        this.actualiteService.getRecoActu(actualite.slug).subscribe(
          (actualites) => {
            this.lastActualites = actualites;
          });

          this.metadataService.updateMetadata({
            title: 'Le Patis Fraux - ' + this.infoBandeau.titre,
            description: 'Actualité : ' + this.infoBandeau.titre
          });
      }
    );
  }

  ngOnInit(): void {
  }

  changeActu(slug : string){
    this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
      this.router.navigate(['actualites/'+slug]);
    });
  }

}
