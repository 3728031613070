import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API = `${environment.api}/api/`;


@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  contact(info: any):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };

    var tabData = {
      "nom": info.nom,
      "prenom": info.prenom,
      "email": info.email,
      "telephone": info.telephone,
      "message": info.message,
    };

    return this.http.post(API+'contact', tabData, httpOptions);
  }

  postuler(cv: any, fileNameCV: string, lettre: any, fileNameLettre: string, info: any, titre: string):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };

    var tabData = {
      'cv': cv,
      'fileNameCV': fileNameCV,
      'lettre': lettre,
      'fileNameLettre': fileNameLettre,
      "nom": info.nom,
      "prenom": info.prenom,
      "email": info.email,
      "telephone": info.telephone,
      "offre": titre,
    };

    return this.http.post(API+'postuler', tabData, httpOptions);
  }

  cadidatureSponatnee(cv: any, fileNameCV: string, lettre: any, fileNameLettre: string, info: any):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };

    var tabData = {
      'cv': cv,
      'fileNameCV': fileNameCV,
      'lettre': lettre,
      'fileNameLettre': fileNameLettre,
      "nom": info.nom,
      "prenom": info.prenom,
      "email": info.email,
      "telephone": info.telephone,
    };

    return this.http.post(API+'cadidaturespontanee', tabData, httpOptions);
  }

  postulerWithoutLettre(cv: any, fileNameCV: string, info: any, titre: string):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };

    var tabData = {
      'cv': cv,
      'fileNameCV': fileNameCV,
      "nom": info.nom,
      "prenom": info.prenom,
      "email": info.email,
      "telephone": info.telephone,
      "offre": titre
    };

    return this.http.post(API+'postuler', tabData, httpOptions);
  }

  spontaneeWithoutLettre(cv: any, fileNameCV: string, info: any):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };

    var tabData = {
      'cv': cv,
      'fileNameCV': fileNameCV,
      "nom": info.nom,
      "prenom": info.prenom,
      "email": info.email,
      "telephone": info.telephone,
    };

    return this.http.post(API+'cadidaturespontanee', tabData, httpOptions);
  }

}
