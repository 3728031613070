import { Component, OnInit } from '@angular/core';
import {PageService} from "../_services/page.service";
import {ActivatedRoute} from "@angular/router";
import {Page} from "../_models/page";
import InfoBandeau from "../_models/info-bandeau";
import { MetadataServiceService } from '../_services/metadata-service.service';

@Component({
  selector: 'app-page-construite',
  templateUrl: './page-construite.component.html',
  styleUrls: ['./page-construite.component.scss']
})
export class PageConstruiteComponent implements OnInit {

  infoBandeau: InfoBandeau = {
    image:'',
    titre:'',
    breadcrumb:''
  };

  page: string = '';
  constructor(private pageService: PageService, private route: ActivatedRoute, private metadataService: MetadataServiceService) { }

  ngOnInit(): void {
    this.infoBandeau = this.route.snapshot.data.infoBandeau;
    let pageId = this.route.snapshot.data.pageId;
    this.pageService.getOnePage(pageId).subscribe({
      next: (page:Page) =>{
        this.page = '<style>'+ page.pageStyle +'</style>' + page.page;

        this.metadataService.updateMetadata({
          title: 'Le Patis Fraux - ' + this.infoBandeau.titre,
          description: 'Le Patis Fraux - ' + this.infoBandeau.titre
        });
      }
    })
  }

}
