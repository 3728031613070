import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebserviceService {

  constructor(private http: HttpClient) { }

  postRequest(url: string, data: any): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    })
    let payload = data; 
    let endPoint = 'http://localhost:10010/api/' + url;
    return this.http.post(endPoint, payload, { headers });
  }
}
