import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Chiffres } from '../_models/chiffres';


@Injectable({
  providedIn: 'root'
})

export class ChiffresService {
  constructor(private http: HttpClient) {
  }

  getChiffres() {
    return this.http.get<Chiffres[]>(`${environment.api}/api/chiffres`);
  }

}
