import { Component, OnInit } from '@angular/core';
import InfoBandeau from "../_models/info-bandeau";
import { MetadataServiceService } from '../_services/metadata-service.service';

@Component({
  selector: 'app-faire-don',
  templateUrl: './faire-don.component.html',
  styleUrls: ['./faire-don.component.scss']
})
export class FaireDonComponent implements OnInit {
  infoBandeau = {
    'image' : './../../assets/bandeau-faire-don.jpg',
    'titre' : 'Soutenir l\'association',
    'breadcrumb' : 'ACCUEIL > Faire un don'
  }
  constructor(private metadataService: MetadataServiceService) { }

  ngOnInit(): void {
    this.metadataService.updateMetadata({
      title: 'Le Patis Fraux - ' + this.infoBandeau.titre,
      description: "Faire un don à l'Association Le Patis Fraux"
    });
  }

}
