<div class="header-div">
  <a class="logo" href="#">
    <img src="./../../assets/Logo-Le-Patis-Fraux-black.png">
  </a>

  <div class="header-container">
    <div class="nav-top-container">
      <div class="search-bar-container">
        <button class="btn btn-menu shadow-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft" aria-controls="offcanvasLeft">
          <i class="fas fa-bars"></i>
        </button>

        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasLeft" aria-labelledby="offcanvasRightLabel">
          <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel"></h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <ul class="navbar-nav mx-md-auto">
              <li class="nav-item">
                <!--routerLink="/qui-sommes-nous" routerLinkActive="active"-->
                <a class="nav-link" routerLink="/etablissement" routerLinkActive="active" data-bs-dismiss="offcanvas">Établissement</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" routerLinkActive="active" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Activités / Services
                </a>
                <ol class="dropdown-menu" aria-labelledby="navbarDropdown2">
                  <li><a class="dropdown-item" routerLink="/pole-sanitaire" routerLinkActive="active" data-bs-dismiss="offcanvas">Pôle Sanitaire</a></li>
                  <li><a class="dropdown-item" routerLink="/pole-medico-social" routerLinkActive="active" data-bs-dismiss="offcanvas">Pôle Médico-social</a></li>
                  <li><a class="dropdown-item" routerLink="/habitat-inclusif" routerLinkActive="active" data-bs-dismiss="offcanvas">Habitat Inclusif</a></li>
                  <li><a class="dropdown-item" routerLink="/maison-sport-sante" routerLinkActive="active" data-bs-dismiss="offcanvas">Maison Sport Santé</a></li>
                </ol>
              </li>

              <li class="nav-item">
                <a class="nav-link" routerLink="/patient-usager" routerLinkActive="active" data-bs-dismiss="offcanvas">Vous êtes patient ou usager</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/formation-prestation" routerLinkActive="active" data-bs-dismiss="offcanvas">Formations</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/actualites" routerLinkActive="active" data-bs-dismiss="offcanvas">Actualités</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/nous-rejoindre" routerLinkActive="active" data-bs-dismiss="offcanvas">Nous rejoindre</a>
              </li>
            </ul>
          </div>
        </div>

        <a routerLink="/"><img src="./../../assets/home.png" class="home"></a>
        <!-- <form class="d-flex form-loupe d-none d-md-flex">
          <button class="btn " type="submit"><img src="./../../assets/loupe.png" class="loupe"></button>
          <input class="form-control shadow-none input-search" type="text" placeholder="Recherche" aria-label="Search">
        </form> -->
      </div>

      <div class="reseau-container">
        <a href="https://www.linkedin.com/company/centre-de-r%C3%A9adaptation-du-patis-fraux/mycompany/?viewAsMember=true" target="_blank">
          <i class="fab fa-linkedin-in linkedin"></i>
        </a>
        <a href="https://twitter.com/PFraux" target="_blank">
          <i class="fab fa-twitter twitter"></i>
        </a>
        <a href="https://www.instagram.com/pfraux/" target="_blank">
          <i class="fab fa-instagram instagram"></i>
        </a>
        <a href="tel:+33299048383" class="last-a">
          <i class="fas fa-phone phone"></i>
        </a>
        <span class="d-sm-block d-none">02 99 04 83 83</span>
      </div>
    </div>
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <a class="navbar-brand" href="#"></a>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav mx-md-auto">
            <li class="nav-item">
              <!--routerLink="/qui-sommes-nous" routerLinkActive="active"-->
              <a class="nav-link" routerLink="/etablissement" routerLinkActive="active">Établissement</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" routerLinkActive="active" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Activités / Services
              </a>
              <ol class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a class="dropdown-item" routerLink="/pole-sanitaire" routerLinkActive="active">Pôle Sanitaire</a></li>
                <li><a class="dropdown-item" routerLink="/pole-medico-social" routerLinkActive="active">Pôle Médico-social</a></li>
                <li><a class="dropdown-item" routerLink="/habitat-inclusif" routerLinkActive="active">Habitat Inclusif</a></li>
                <li><a class="dropdown-item" routerLink="/maison-sport-sante" routerLinkActive="active">Maison Sport Santé</a></li>
              </ol>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/patient-usager" routerLinkActive="active">Vous êtes patient ou usager</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/formation-prestation" routerLinkActive="active">Formations</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/actualites" routerLinkActive="active">Actualités</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/nous-rejoindre" routerLinkActive="active">Nous rejoindre</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</div>
