import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Doc } from '../_models/doc';


@Injectable({
  providedIn: 'root'
})

export class DocService {
  constructor(private http: HttpClient) {
  }

  getDocsByCat(slug : string) {
    return this.http.get<Doc[]>(`${environment.api}/api/documents/categorie/`+slug);
  }

}
