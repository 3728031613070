import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Inject, PLATFORM_ID } from '@angular/core';
import { MetadataServiceService } from '../_services/metadata-service.service';

@Component({
  selector: 'app-etablissement',
  templateUrl: './etablissement.component.html',
  styleUrls: ['./etablissement.component.scss']
})
export class EtablissementComponent implements OnInit {

  infoBandeau = {
    'image' : './../../assets/bandeau-etablissement.png',
    'titre' : 'établissement',
    'breadcrumb' : 'ACCUEIL > Établissement'
  }
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private metadataService: MetadataServiceService) { }

  ngOnInit(): void {
    this.metadataService.updateMetadata({
      title: 'Le Patis Fraux - Établissement',
      description: "Présentation de l'Association Le Patis Fraux"
    });
  }

  openPdf(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.open("../../assets/organigramme-patis-fraux-octobre-2023.pdf", '_blank');
    }
  }
}
