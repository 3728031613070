import { Component, OnInit } from '@angular/core';
import { MetadataServiceService } from '../_services/metadata-service.service';

@Component({
  selector: 'app-politique-de-confidentialite',
  templateUrl: './politique-de-confidentialite.component.html',
  styleUrls: ['./politique-de-confidentialite.component.scss']
})
export class PolitiqueDeConfidentialiteComponent implements OnInit {

  infoBandeau = {
    'image' : './../../assets/bandeau-etablissement.png',
    'titre' : 'Politique de confidentialité',
    'breadcrumb' : 'ACCUEIL > Politique de confidentialité'
  }

  constructor(private metadataService: MetadataServiceService) { }

  ngOnInit(): void {
    this.metadataService.updateMetadata({
      title: 'Le Patis Fraux - ' + this.infoBandeau.titre,
      description: 'Le Patis Fraux - ' + this.infoBandeau.titre
    });
  }

}
