import { Component, OnInit } from '@angular/core';
import { Offre } from '../_models/offre';
import { OffreService } from '../_services/offre.service';
import { MatDialog } from '@angular/material/dialog';
import { NousRejoindreSpontaneComponent } from '../nous-rejoindre-spontane/nous-rejoindre-spontane.component';
import { MetadataServiceService } from '../_services/metadata-service.service';

@Component({
  selector: 'app-nous-rejoindre',
  templateUrl: './nous-rejoindre.component.html',
  styleUrls: ['./nous-rejoindre.component.scss']
})
export class NousRejoindreComponent implements OnInit {

  infoBandeau = {
    'image' : './../../assets/bandeau-nous-rejoindre.jpg',
    'titre' : "REJOINDRE l'Association Le Patis Fraux",
    'breadcrumb' : 'ACCUEIL > Nous rejoindre'
  }

  offres: Offre[] = [];

  constructor(private offreService: OffreService, private dialog: MatDialog, private metadataService: MetadataServiceService) {
    this.offreService.getOffresDisplayed().subscribe(
      (offres) => {
        this.offres = offres
      }
    );
  }

  ngOnInit(): void {
    this.metadataService.updateMetadata({
      title: 'Le Patis Fraux - ' + this.infoBandeau.titre,
      description: 'Pages listant les offres d\'emplois du Centre de Réadaptation de Patis Fraux'
    });
  }

  openDialog() {
    this.dialog.open(NousRejoindreSpontaneComponent, {
      panelClass: "modal-spontanee",
      // height: '65%',
      width: '50%',
    });
  }
}
