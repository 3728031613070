<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image" [titreBandeau]="infoBandeau.titre" ></app-bandeau-page>
<app-donation-onglet></app-donation-onglet>
<section class="formation__section">
  <div class="container-fuid g-0">
    <div class="row bg-gray row-usager d-flex justify-content-center pt-5 mt-3">
      <div class="col-xxl-4 col-xl-4 col-md-10 col-11">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <h2 class="texte">Nos formations</h2>
        </div>
        <img src="../../../assets/logo-qualiopi.png" class="img-fluid my-3" alt="Logo Qualiopi">
        <p>
          <a target="_blank" href="https://s3-eu-west-3.amazonaws.com/patisfraux.fr/fichier/documents/pg2402-certificat-rnq-association-le-patis-fraux-649af2538fd45666709120.pdf">Patis Learn</a>, notre organisme de formation s’appuie sur les expertises métiers, les compétences et
          savoir-faire des professionnels de l’établissement pour proposer des formations adaptées aux besoins
          et attentes des stagiaires ainsi qu’aux différents publics accueillis. L’organisme de formation Patis
          Learn est certifié QUALIOPI depuis le 9 décembre 2022.
        </p>
      </div>
      <div class="col-xxl-4 ms-xl-5 col-xl-4 col-md-10 col-11">
        <div class="red-arrow-container">
          <img src="../../../assets/logo-patis-learn.png" class="img-fluid m-md-5" alt="Logo Patis Learn">
          <a [href]="doc.url" *ngFor="let doc of docFormation"><img src="./../../assets/puce-texte.png"><span>{{ doc.titre }}</span></a>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-center mt-5">
      <div class="col-xxl-4 ms-xl-5 col-xl-4 col-md-10 col-11">
        <div class="red-arrow-container">
          <a [href]="doc.url" *ngFor="let doc of docPresta"><img src="./../../assets/puce-texte.png"><span>{{ doc.titre }}</span></a>
        </div>
      </div>
      <div class="col-xxl-4 col-xl-4 col-md-10 col-11">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <h2 class="texte">Prévention des Risques liés à l’Activité Physique (PRAP)</h2>
        </div>
        <div>
          La formation PRAP a pour objectif de permettre au salarié de concourir à l’amélioration des
          conditions de travail afin de réduire les risques d’accidents du travail ou de maladies
          professionnelles. Elle permet à tout professionnel de devenir un acteur de prévention et
          d’être force de proposition en matière d’amélioration des risques professionnels.
          La formation Maintien et Actualisation des Compétences (MAC) permet de renouveler la
          certification d’acteur PRAP. <br> <br>

          <div style="color: #424242; cursor: pointer;" (click)="assignerContenu('PRAP2S')">
            <i class="fas fa-plus" ></i>  PRAP Sanitaire et Médico-Social (2S)
          </div>
          <div id="prap2S" class="expanded-formation" *ngIf="prap2S.isExpanded">
            La formation PRAP 2S est à destination de tous les salariés, opérateurs et agents du secteur
            sanitaire et médico-social (établissements de santé, structures d’aides et de soins à la
            personne, EHPAD...). <br><br>
            La formation PRAP 2S intègre la démarche d’Accompagnement à la Mobilité (ALM). Elle
            permet d’accompagner la mobilité de la personne aidée en prenant soin de l’autre et de soi.
            Cette démarche vise à prévenir l’exposition au risque de troubles musculosquelettiques
            (TMS) et aux chutes liées au portage des patients. <a class="link" target="_blank" href="../../assets/demarche-ALM.pdf"> Pour en savoir plus sur la démarche (ALM)</a>

            <h3 class="know-more">Pour en savoir plus</h3>
            <a class="link" target="_blank" href="../../assets/fiches-formations/formation-PRAP-2S.pdf">La fiche formation PRAP 2S</a><br>
            <a class="link" href="formation-prestation/#contact">Prendre contact avec l'organisme de formation Patis Learn</a>
          </div>

          <div style="color: #424242; cursor: pointer;" (click)="assignerContenu('MACPRAP2S')">
            <i class="fas fa-plus" ></i> Formation MAC PRAP 2S
          </div>
          <div id="prap2S" class="expanded-formation" *ngIf="macprap2S.isExpanded">
            La formation MAC PRAP 2S est à réaliser tous les 2 ans après la formation acteur PRAP 2S
            afin que le certificat d’acteur PRAP 2S puisse rester valide. Elle permet à tout professionnel
            de continuer d’être un acteur de prévention et d’être force de proposition en matière
            d’amélioration des risques professionnels.

            <h3 class="know-more">Pour en savoir plus</h3>
            <a class="link" target="_blank" href="../../assets/fiches-formations/formation-MAC-PRAP-2S.pdf">La fiche formation MAC PRAP 2S</a><br>
            <a class="link" href="formation-prestation/#contact">Prendre contact avec l'organisme de formation Patis Learn</a>
          </div>

          <div style="color: #424242; cursor: pointer;" (click)="assignerContenu('PRAPIBC')">
            <i class="fas fa-plus" ></i> PRAP Industrie Bâtiment Commerce (IBC)
          </div>
          <div id="prap2S" class="expanded-formation" *ngIf="prapIBC.isExpanded">
            La formation PRAP IBC s’adresse à tous les salariés, opérateurs et agents d’une entreprise
            appartenant au secteur Industrie-Bâtiment-Commerce, et activités de bureau.

            <h3 class="know-more">Pour en savoir plus</h3>
            <a class="link" target="_blank" href="../../assets/fiches-formations/formation-PRAP-IBC.pdf">La fiche formation PRAP IBC</a> <br>
            <a class="link" href="formation-prestation/#contact">Prendre contact avec l'organisme de formation Patis Learn</a>
          </div>

          <div (click)="assignerContenu('MACPRAPIBC')" style="color: #424242; cursor: pointer;">
            <i class="fas fa-plus"></i> Formation MAC PRAP IBC
          </div>

          <div id="prap2S" class="expanded-formation" *ngIf="macprapIBC.isExpanded">
            La formation MAC PRAP IBC est à réaliser tous les 2 ans après la formation acteur PRAP IBC
            afin que le certificat d’acteur PRAP IBC puisse rester valide. Elle permet à tout professionnel
            de continuer d’être un acteur de prévention et d’être force de proposition en matière
            d’amélioration des risques professionnels.

            <h3 class="know-more">Pour en savoir plus</h3>
            <a class="link" target="_blank" href="../../assets/fiches-formations/formation-MAC-PRAP-IBC.pdf">La fiche formation MAC PRAP IBC</a><br>
            <a class="link" href="formation-prestation/#contact">Prendre contact avec l'organisme de formation Patis Learn</a>
          </div>

        </div>
      </div>
    </div>

    <div class="row bg-gray row-usager d-flex justify-content-center pt-5 mt-3">
      <div class="col-xxl-4 col-xl-4 col-md-10 col-11">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <h2 class="texte">Formation Initiation aux réseaux sociaux</h2>
        </div>
        <p>
          La formation initiation aux réseaux sociaux est destinée aux personnes qui souhaitent
          développer la communication de leur structure et plus particulièrement sur les réseaux
          sociaux. Cette formation donne les clés des principes de fonctionnement des différents
          réseaux sociaux, d’appréhender la communication sur les réseaux, de créer et gérer les
          pages entreprises et de cibler les réseaux en fonction des attentes.
        </p>
        <h3 class="know-more">Pour en savoir plus</h3>
        <a class="link" target="_blank" href="../../assets/fiches-formations/formation-reseaux-sociaux.pdf">La fiche formation initiation aux réseaux sociaux</a><br>
        <a class="link" href="formation-prestation/#contact">Prendre contact avec l'organisme de formation Patis Learn</a>
      </div>
      <div class="col-xxl-4 ms-xl-5 col-xl-4 col-md-10 col-11">
        <div class="red-arrow-container">
          <a [href]="doc.url" *ngFor="let doc of docFormation"><img src="./../../assets/puce-texte.png"><span>{{ doc.titre }}</span></a>
        </div>
      </div>
    </div>

    <div class="row row-usager d-flex justify-content-center pt-5 mt-3">
      <div class="col-xxl-4 ms-xl-5 col-xl-4 col-md-10 col-11">
        <div class="red-arrow-container">
          <a [href]="doc.url" *ngFor="let doc of docFormation"><img src="./../../assets/puce-texte.png"><span>{{ doc.titre }}</span></a>
        </div>
      </div>
      <div class="col-xxl-4 col-xl-4 col-md-10 col-11">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <h2 class="texte">Accessibilité</h2>
        </div>
        <h3 class="know-more">Accès aux personnes en situation de handicap</h3>
        <p>
          Patis Learn s’appuyant sur les compétences du Centre de Réadaptation
          professionnelle du Patis Fraux, nos formations sont par nature
          accessibles aux personnes en situation de handicap.
        </p>
        <p>
          Les actions de formation font l’objet d’une préparation en amont
          qui prend en considération les aménagements nécessaires permettant
          l’accessibilité pour tous (accès, supports ou méthodes pédagogiques).<br>
          Chaque situation étant unique, nous vous proposons (si besoin) un
          échange téléphonique avec votre formateur ou notre référent handicap.
        </p>
        <p>
          Nous vous offrons ainsi toutes les garanties de suivre votre
          formation dans les meilleures conditions.
        </p>
      </div>
    </div>

    <div id="contact" class="row bg-gray row-usager d-flex justify-content-center pt-5 mt-3">
      <div class="col-xxl-4 col-xl-4 col-md-10 col-11">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <h2 class="texte">Contact</h2>
        </div>
        Centre de formation Patis Learn <br>
        <a class="link" href="mailto:learn@patisfraux.fr">learn@patisfraux.fr</a><br>
        <a class="link" href="tel:+299048303">02 99 04 83 83</a>
      </div>
      <div class="col-xxl-4 ms-xl-5 col-xl-4 col-md-10 col-11">
        <div class="red-arrow-container">
          <a [href]="doc.url" *ngFor="let doc of docFormation"><img src="./../../assets/puce-texte.png"><span>{{ doc.titre }}</span></a>
        </div>
      </div>
    </div>

    <!-- <div class="row bg-gray row-usager d-flex justify-content-center pt-5 mt-3">
      <div class="col-xxl-4 col-xl-4 col-md-10 col-11">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <h2 class="texte">Plateforme Territoriale de Réadaptation Professionnelle</h2>
        </div>
        <p>
          Les 3 opérateurs de la réadaptation professionnelle du territoire brétillien, le Centre de
          Réadaptation du Patis Fraux, l’EPNAK et LADAPT se sont engagés dans une démarche
          partenariale de co-construction d’une Plateforme Territoriale de Réadaptation
          Professionnelle. Chacune de ces trois structures dispose de ressources et d’une offre de
          prestations spécifiques et complémentaires, dans le domaine de l’orientation et de la
          formation des personnes reconnues en qualité de Travailleurs Handicapés. <br><br>
          Ce partenariat s’est traduit par la mise en œuvre d’un <a class="link" target="_blank" href="../../assets/fiches-formations/Catalogue-PTRP.pdf">catalogue commun de prestations</a>,
          d’outils et de compétences que les trois établissements mettent en commun
          pour optimiser les parcours des personnes orientées vers la réadaptation professionnelle en
          développant la dynamique inter-établissements. <br><br>
          L'Association Le Patis Fraux propose différentes mises en situation (conduite
          d’engins de manutention, Travaux courants d’entretien des espaces verts et travaux
          extérieurs, activités de conduite de véhicules utilitaires légers, Activités de magasinage de
          type « Employé de Libre-Service »…) de la prévention (gestes et postures d’économie
          articulaire, gestion de la douleur, Sensibilisation à la gestion de l’effort).
        </p>
        <h3 class="know-more">Contact</h3>
        Secrétariat du Service de Réadaptation Professionnelle <br>
        <a class="link" href="mailto:srp@patisfraux.fr">srp@patisfraux.fr</a> <br>
        <a class="link" href="tel:+299048303">02 99 04 83 03</a>
      </div>
      <div class="col-xxl-4 ms-xl-5 col-xl-4 col-md-10 col-11">
        <div class="red-arrow-container">
          <a [href]="doc.url" *ngFor="let doc of docFormation"><img src="./../../assets/puce-texte.png"><span>{{ doc.titre }}</span></a>
        </div>
      </div>
    </div> -->

  </div>
</section>
