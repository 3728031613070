import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bandeau-page',
  templateUrl: './bandeau-page.component.html',
  styleUrls: ['./bandeau-page.component.scss']
})
export class BandeauPageComponent implements OnInit {

  @Input() linkBandeau : string = "";
  @Input() titreBandeau : string = "ACCUEIL";
  @Input() breadcrumb : string = "ACCUEIL";
  constructor() { }

  ngOnInit(): void {
  }

}
