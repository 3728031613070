<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image" [titreBandeau]="infoBandeau.titre" ></app-bandeau-page>
<app-donation-onglet></app-donation-onglet>
<section class="sanitaire__section">
  <div class="container-fuid g-0">
    <div class="row row-page d-flex justify-content-center">
      <div class="col-xxl-6 col-xl-5 col-md-10 d-flex justify-content-start">
        <img class="bg-grey col-11" src="./../../assets/le-clos-orriere.jpg">
      </div>
      <div class="col-xxl-4 col-xl-6 col-md-10 col-11">
        <div class="title-with-pill mb-4">
          <span class="pill-title"></span>
          <h2 class="texte">EHPAD du Clos d’Orrière</h2>
        </div>
        <p>
          L’EHPAD du Clos d’Orrière accueille 92 résidents en hébergement permanent dont
          20 en unité de vie protégée et propose 6 places en accueil de jour dont peuvent
          bénéficier des personnes présentant des troubles cognitifs.
        </p>
        <p>
          Les professionnels de l’établissement accompagnent des personnes âgées dépendantes
          en leur garantissant un projet de vie et de soins adapté aux besoins et attentes de chaque résident.
        </p>
        <div class="d-flex justify-content-end">
          <a class="btn btn-grey" [routerLink]="['/pole-medico-social/ehpad-clos-orriere']">En savoir plus</a>
        </div>
      </div>
    </div>
    <div class="row row-page d-flex justify-content-center">
      <div class="col-xxl-4 col-xl-5 col-md-10 col-11">
        <div class="title-with-pill mb-4">
          <span class="pill-title"></span>
          <h2 class="texte">Service de Réadaptation professionnelle</h2>
        </div>
        <p>Le <strong>Service de  Réadaptation Professionnelle</strong> est un établissement médico-social agréé par l’ARS Bretagne pour 40 places, avec une possibilité d’hébergement. Il accueille des personnes reconnues «Travailleur Handicapé» inscrites dans une démarche de reprise d’activité professionnelle compatible avec leur santé et propose un accompagnement global à la réalisation de leur projet.</p>
        <div class="d-flex justify-content-end">
          <a class="btn btn-grey" [routerLink]="['/pole-medico-social/service-readaptation-professionnelle']">En savoir plus</a>
        </div>
      </div>
      <div class="col-xxl-6 col-xl-6 col-md-10 d-flex justify-content-end">
        <img class="bg-grey col-11" src="./../../assets/SRP.jpg">
      </div>
    </div>
    <div class="row row-page d-flex justify-content-center">
      <div class="col-xxl-6 col-xl-5 col-md-10 d-flex justify-content-start">
        <img class="bg-grey col-11" src="./../../assets/accueil-temphau.jpg">
      </div>
      <div class="col-xxl-4 col-xl-6 col-md-10 col-11">
        <div class="title-with-pill mb-4">
          <span class="pill-title"></span>
          <h2 class="texte">Service d’accueil temporaire – TempHau</h2>
        </div>
        <p>Le service d’accueil temporaire TempHau a pour mission d’accompagner des personnes en situation de handicap vivant à domicile ou en établissement.<br>
          L’accueil temporaire vise ainsi à :
        </p>
        <ul>
          <li>Apporter une aide aux proches aidants (parents, conjoint, enfants…) ;</li>
          <li>Répondre à certaines situations d’urgence ;</li>
          <li>Préparer le relais en lien avec une structure d’hébergement définitif ;</li>
          <li>Maintenir ou développer les acquis et l’autonomie de la personne ;</li>
          <li>Préserver ou favoriser l’inclusion sociale des personnes accueillies.</li>
        </ul>
        <div class="d-flex justify-content-end">
          <a class="btn btn-grey" [routerLink]="['/pole-medico-social/service-accueil-temporaire']">En savoir plus</a>
        </div>
      </div>
    </div>
    <div class="row row-page d-flex justify-content-center">
      <div class="col-xxl-4 col-xl-6 col-md-10 col-11">
        <div class="title-with-pill mb-4">
          <span class="pill-title"></span>
          <h2 class="texte">Foyer d’Hébergement pour Adultes Handicapés</h2>
        </div>
        <p>Le service accueille des personnes de plus de 18 ans reconnues en situation de handicap par la Maison Départementale des Personnes Handicapées (MDPH), disposant d’une orientation foyer d’hébergement par la Commission des Droits et de l’Autonomie des Personnes Handicapées (CDAPH) et exerçant une activité professionnelle.</p>
        <div class="d-flex justify-content-end">
          <a class="btn btn-grey" [routerLink]="['/pole-medico-social/foyer-hebergement-adultes-handicapes']">En savoir plus</a>
        </div>
      </div>
      <div class="col-xxl-6 col-xl-5 col-md-10 d-flex justify-content-end">
        <img class="bg-grey col-11" src="./../../assets/FHAH.jpg">
      </div>
    </div>
    <div class="row row-page d-flex justify-content-center">
      <div class="col-xxl-6 col-xl-5 col-md-10 d-flex justify-content-start">
        <img class="bg-grey col-11" src="./../../assets/vie-social.jpg">
      </div>
      <div class="col-xxl-4 col-xl-6 col-md-10 col-11">
        <div class="title-with-pill mb-4">
          <span class="pill-title"></span>
          <h2 class="texte">Service d’accompagnement à la vie sociale</h2>
        </div>
        <p>Le Service d’accompagnement à la vie sociale (SAVS) a pour mission d’accompagner les adultes en situation de handicap vers le maintien ou le développement de leur autonomie et des relations avec leur environnement en favorisant l’acquisition des habiletés sociales. Cet accompagnement peut être individuel et/ou collectif en fonction des besoins identifiés lors de l’élaboration du projet d’accompagnement individualisé de la personne. </p>
        <div class="d-flex justify-content-end">
          <a class="btn btn-grey" [routerLink]="['/pole-medico-social/service-accompagnement-vie-sociale']">En savoir plus</a>
        </div>
      </div>
    </div>
    <div class="row row-page d-flex justify-content-center">
      <div class="col-xxl-4 col-xl-6 col-md-10 col-11">
        <div class="title-with-pill mb-4">
          <span class="pill-title"></span>
          <h2 class="texte">Service d’Accompagnement Médico-Social pour Adultes Handicapés</h2>
        </div>
        <p>
          Le Service d’Accompagnement Médico-Social pour Adultes Handicapés (SAMSAH) a pour mission de favoriser le maintien et/ou le développement de l’autonomie des personnes en situation de handicap par un accompagnement et une assistance dans la réalisation des actes de la vie quotidienne. Le SAMSAH réalise et coordonne également les soins médicaux et paramédicaux.
        </p>
        <div class="d-flex justify-content-end">
          <a class="btn btn-grey" [routerLink]="['/pole-medico-social/service-accompagnement-adultes-hadicapes']">En savoir plus</a>
        </div>
      </div>
      <div class="col-xxl-6 col-xl-5 col-md-10 d-flex justify-content-end">
        <img class="bg-grey col-11" src="./../../assets/SAMSAH.jpg">
      </div>
    </div>
  </div>
</section>
