import { Component, AfterViewInit } from '@angular/core';
// import * as L from 'leaflet';
import { Doc } from '../_models/doc';
import { DocService } from '../_services/doc.service';
import { MetadataServiceService } from '../_services/metadata-service.service';
;

@Component({
  selector: 'app-patients-usager',
  templateUrl: './patients-usager.component.html',
  styleUrls: ['./patients-usager.component.scss']
})
export class PatientsUsagerComponent implements AfterViewInit {

  infoBandeau = {
    'image' : "./../../assets/bandeau-vous-etes-patient-ou-usager.jpg",
    'titre' : 'VOUS ÊTES PATIENT OU USAGER',
    'breadcrumb' : 'ACCUEIL > Vous êtes patient ou usager'
  }

  docAccueil : Doc[] = [];
  docPreparer : Doc[] = [];

  get map() {
    return this._map;
  }
  private _map: any;

  private initMap(): void {
    // this._map = L.map('map', {
    //   center: [ 48.0460636, -1.6123677 ],
    //   zoom: 11
    // });

    // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   maxZoom: 18,
    //   minZoom: 3,
    //   attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    // });

    // const marker = L.marker([48.04542, -1.61027]);

    // marker.addTo(this.map);
    // tiles.addTo(this.map);
  }

  constructor(private docService : DocService, private metadataService: MetadataServiceService) {
    this.docService.getDocsByCat('livret-accueil').subscribe(
      (docAccueil) => {
        this.docAccueil = docAccueil
      }
    );
    this.docService.getDocsByCat('preparer-son-arrive').subscribe(
      (docPreparer) => {
        this.docPreparer = docPreparer
      }
    );
  }

  ngAfterViewInit(): void {
    this.initMap();
    this.metadataService.updateMetadata({
      title: 'Le Patis Fraux - ' + this.infoBandeau.titre,
      description: 'Le Patis Fraux - ' + this.infoBandeau.titre
    });
  }

}
