import { Component, OnInit } from '@angular/core';
import { Offre } from '../_models/offre';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ContactService } from '../_services/contact.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-nous-rejoindre-spontane',
  templateUrl: './nous-rejoindre-spontane.component.html',
  styleUrls: ['./nous-rejoindre-spontane.component.scss']
})
export class NousRejoindreSpontaneComponent implements OnInit {
  submitted = false;
  postulationForm : any = [];
  offre : Offre = new Offre();
  fileToUploadCV: File | any = null;
  fileToUploadLettre: File | any = null;
  constructor(private dialog: MatDialog, private route: ActivatedRoute, private formBuilder: FormBuilder, private contactService : ContactService, readonly snackBar: MatSnackBar) {

    this.postulationForm = this.formBuilder.group({
      cv: new FormControl('', [
        Validators.required
      ]),
      lettre: new FormControl(''),
      slugOffre: new FormControl(''),
      nom: new FormControl('', [
        Validators.required
      ]),
      prenom: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required
      ]),
      telephone: new FormControl('', [
        Validators.required
      ]),
      check: new FormControl('', [
        Validators.required
      ])
    });
  }

  ngOnInit(): void {
  }
  get f() { return this.postulationForm.controls; }

  handleFileInputCV(event: any) {
    this.fileToUploadCV = event.target.files.item(0);
  }
  handleFileInputLettre(event: any) {
    this.fileToUploadLettre = event.target.files.item(0);
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        const result = reader.result;
        if (result !== null) {
          resolve(result as string | ArrayBuffer);
        } else {
          reject(null);
        }
      };

      reader.onerror = e => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };

      if (!file) {
        console.error('No file to read.');
        return reject(null);
      }

      reader.readAsDataURL(file);
    });
  }

  onSubmit(): void{
    this.submitted = true;

    if (this.postulationForm.invalid) {
      return;
    }
    // this.postulationForm.controls['slugOffre'].setValue(this.offre.slug);

    if(this.fileToUploadCV){
      if(this.fileToUploadLettre){
        this.readFile(this.fileToUploadCV).then(fileContents => {
          this.readFile(this.fileToUploadLettre).then(fileContentsLettre => {
            this.contactService.cadidatureSponatnee(fileContents, this.fileToUploadCV.name, fileContentsLettre, this.fileToUploadLettre.name, this.postulationForm.value).subscribe(data => {
              this.postulationForm.reset();  // Reset the form
              this.submitted = false;
              this.snackBar.open('Merci pour votre candidature, elle a bien été reçue par le service des Ressources Humaines. Nous allons étudier vos expériences et qualifications dans les plus brefs délais.', '', {
                duration: 5000,
                horizontalPosition: 'right',
                verticalPosition: 'top',
                panelClass: ['snackbar-success']
              });
            });
          });
        });
        setTimeout(() => {
          this.dialog.closeAll();
        }, 5000);
      } else {
        this.readFile(this.fileToUploadCV).then(fileContents => {
          this.contactService.spontaneeWithoutLettre(fileContents, this.fileToUploadCV.name, this.postulationForm.value).subscribe(data => {
            this.postulationForm.reset();  // Reset the form
            this.submitted = false;
            this.snackBar.open('Merci pour votre candidature, elle a bien été reçue par le service des Ressources Humaines. Nous allons étudier vos expériences et qualifications dans les plus brefs délais.', '', {
              duration: 5000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: ['snackbar-success']
            });
          });
        });
        setTimeout(() => {
          this.dialog.closeAll();
        }, 5000);
      }
      } else {

      }
  }
}
