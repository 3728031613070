import { Component, OnInit } from '@angular/core';
import { MetadataServiceService } from '../_services/metadata-service.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private metadataService: MetadataServiceService) { }

  ngOnInit(): void {
    this.metadataService.updateMetadata({
      title: 'Le Patis Fraux - Page introuvable',
      description: 'Le Patis Fraux - Page introuvable'
    });
  }

}
