import { Component, OnInit } from '@angular/core';
import { MetadataServiceService } from '../_services/metadata-service.service';

@Component({
  selector: 'app-mentions-legales',
  templateUrl: './mentions-legales.component.html',
  styleUrls: ['./mentions-legales.component.scss']
})
export class MentionsLegalesComponent implements OnInit {

  infoBandeau = {
    'image' : './../../assets/bandeau-etablissement.png',
    'titre' : 'Mentions légales',
    'breadcrumb' : 'ACCUEIL > Mentions légales'
  }

  constructor(private metadataService: MetadataServiceService) { }

  ngOnInit(): void {
    this.metadataService.updateMetadata({
      title: 'Le Patis Fraux - ' + this.infoBandeau.titre,
      description: 'Mentions légales relatives à la navigation sur le site'
    });
  }

}
