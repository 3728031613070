import { Component, OnInit } from '@angular/core';
import { Actu } from '../_models/actu';
import { ActuService } from '../_services/actu.service';

import { ChiffresService } from '../_services/chiffres.service';
import { Chiffres } from "../_models/chiffres";

import { SliderService } from '../_services/slider.service';
import { Slider } from "../_models/slider";
import { Offre } from '../_models/offre';
import { OffreService } from '../_services/offre.service';
import { MetadataServiceService } from '../_services/metadata-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  actu: Actu[] = [];
  chiffres: Chiffres[] = [];
  slider: Slider[] = [];
  offres: Offre[] = [];
  nbOffre: number = 0;

  constructor(
    private actuService: ActuService,
    private chiffreService: ChiffresService,
    private sliderService: SliderService,
    private offreService: OffreService,
    private metadataService: MetadataServiceService
  ) {
    this.actuService.getLastActu().subscribe(
      (actu) => this.actu = actu
    );

    this.chiffreService.getChiffres().subscribe(
      (chiffres) => {
        this.chiffres = chiffres
      }
    );

    this.sliderService.getSlidersDisplayed().subscribe(
      (slider)=> this.slider = slider
    )

    this.offreService.getLatestOffre().subscribe(
      (offres) => {
        this.offres = offres
        this.nbOffre = this.offres.length;
      }
    )
  }

  ngOnInit(): void {
    this.metadataService.updateMetadata({
      title: 'Le Patis Fraux - Accueil',
      description: 'Page d\'acceuil Le Patis Fraux'
    });
  }

}
