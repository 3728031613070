import { Component, OnInit } from '@angular/core';
import { MetadataServiceService } from '../_services/metadata-service.service';

@Component({
  selector: 'app-pole-sanitaire',
  templateUrl: './pole-sanitaire.component.html',
  styleUrls: ['./pole-sanitaire.component.scss']
})
export class PoleSanitaireComponent implements OnInit {

  infoBandeau = {
    'image' : "./../../assets/bandeau-smr.jpg",
    'titre' : 'Service de Soins Médicaux et de Réadaptation',
    'breadcrumb' : 'ACCUEIL > Service de Soins Médicaux et de Réadaptation'
  }

  constructor(private metadataService: MetadataServiceService) { }

  ngOnInit(): void {
    this.metadataService.updateMetadata({
      title: 'Le Patis Fraux - ' + this.infoBandeau.titre,
      description: 'Le Patis Fraux - ' + this.infoBandeau.titre
    });
  }

}
