import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Actu } from '../_models/actu';


@Injectable({
  providedIn: 'root'
})

export class ActuService {
  constructor(private http: HttpClient) {
  }

  getLastActu() {
    return this.http.get<Actu[]>(`${environment.api}/api/actualites/last`);
  }
  getActus() {
    return this.http.get<Actu[]>(`${environment.api}/api/actualites/displayed`);
  }
  getBySlug(slug: string) {
    return this.http.get<Actu>(`${environment.api}/api/actualites/slug/${slug}`);
  }
  getRecoActu(slug: string) {
    return this.http.get<Actu[]>(`${environment.api}/api/actualites/reco/${slug}`);
  }

}
