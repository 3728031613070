<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image" [titreBandeau]="infoBandeau.titre" ></app-bandeau-page>
<app-donation-onglet></app-donation-onglet>
<section class="partenaires__section">
  <div class="container-fuid g-0">
    <div class="row bg-gray row-usager d-flex justify-content-center">
      <div class="col-lg-9 col-md-10 col-11 d-flex flex-column align-items-center text-center">
        <div class="title-with-pill">
          <span class="pill-title"></span>
          <span class="texte">Partenaires</span>
        </div>
        <h2>Les partenaires de l'Association Le Patis Fraux.</h2>
        <!-- <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren</p> -->
    </div>
  </div>
  </div>
  <div class="row bg-gray row-actu" *ngIf="nbPartenaire; else elseBlock">
    <div class="col-lg-4 col-md-12" *ngFor="let partenaire of partenaires">
      <div class="card">
        <a *ngIf="partenaire.lien; else second" [href]="partenaire.lien" target="_blank">
          <div style="background-image: url({{partenaire.logo}});" class="card-img-top"></div>
          <div class="card-body">
            <h3 class="card-title"><span>—</span> {{ partenaire.name }}</h3>
          </div>
          <img class="puce" src="../../assets/puce-texte.png">
        </a>
        <ng-template #second>
          <div style="background-image: url({{partenaire.logo}});" class="card-img-top"></div>
          <div class="card-body">
            <h3 class="card-title"><span>—</span> {{ partenaire.name }}</h3>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <ng-template #elseBlock>
    <div class="col-12 bg-gray text-center py-5">
      Aucun partenaire pour le moment.
    </div>
  </ng-template>
</section>
