<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image" [titreBandeau]="infoBandeau.titre" ></app-bandeau-page>
<app-donation-onglet></app-donation-onglet>
<section id="page-actualite">
    <div class="row col-12 justify-content-center top-actu">
        <div class="col-xxl-6 col-10">
            <a class="btn btn-grey mb-4" [routerLink]="['/actualites']">Retour aux actualités</a>
        </div>
        <div class="col-xxl-2"></div>
    </div>
    <div class="row col-12 justify-content-center">
        <div class="col-xxl-8 col-sm-10">
            <p>Publié le {{ actualite.dateCreation.date | date : 'd LLLL, y' : '' : 'fr' }}</p>
        </div>
        <div class="col-xxl-6 col-sm-10" id="content-page-actu" [innerHTML]="page | safeHtml"></div>
        <div class="col-xxl-2 col-sm-10 bloc-last-actu">
            <div class="bloc-title">
                <span class="title-last-actu">Actualités</span>
            </div>
            <div *ngFor="let actu of lastActualites; let first = first; let last = last">
                <a (click)="changeActu(actu.slug)" [ngClass]="{ first: first, last: last }">
                    <div class="bloc-last-actu__img" style="background-image: url({{actu.urlImage}});"></div>
                    <div class="bloc-last-actu__content">
                        <span>{{ actu.titre }}</span><br>
                        <span class="learn-more">En savoir plus</span>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
