<div id="app-site">
    <!-- Bandeau -->
    <app-header id="app-header"></app-header>

    <!-- Contenu -->
    <section id="app-content">
        <router-outlet></router-outlet>
    </section>

    <!-- Bas de page -->
    <app-footer id="app-footer"></app-footer>
</div>