export class Offre {
  id!: number;
  titre!: string;
  description!: string;
  categorie!: string;
  texteVignette!: string;
  reference!: string;
  slug!: string;
  urgent!: boolean;
  date!: { date: string };
}
