import { Component, OnInit } from '@angular/core';
import { MetadataServiceService } from '../_services/metadata-service.service';

@Component({
  selector: 'app-pole-medico-social',
  templateUrl: './pole-medico-social.component.html',
  styleUrls: ['./pole-medico-social.component.scss']
})
export class PoleMedicoSocialComponent implements OnInit {

  infoBandeau = {
    'image' : "./../../assets/bandeau-site-web-medico-social.jpg",
    'titre' : 'Pôle médico-social',
    'breadcrumb' : 'ACCUEIL > Pôle medico-social'
  }

  constructor(private metadataService: MetadataServiceService) { }

  ngOnInit(): void {
    this.metadataService.updateMetadata({
      title: 'Le Patis Fraux - ' + this.infoBandeau.titre,
      description: 'Le Patis Fraux - ' + this.infoBandeau.titre
    });
  }

}
