<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image" [titreBandeau]="infoBandeau.titre" ></app-bandeau-page>
<app-donation-onglet></app-donation-onglet>
<section class="contact-form-section">
    <div class="container">
        <div class="row">
            <div class="col-xxl-4 col-xl-12 text-xxl-start text-center left-col">
                <h2>Adresse</h2>
                <p>
                  2, allée Salvador Dali
                  <br/>35770 VERN SUR SEICHE
                </p>
                <div class="border-left"></div>
                <br>
                <h2>Téléphone</h2>
                <p class="phone">
                    Numéro : <a href="tel:+33299048383">02&nbsp;99&nbsp;04&nbsp;83&nbsp;83</a>
                </p>
                <div class="border-left"></div>
                <br>
            </div>

            <div class="col-xxl-8 mx-auto">
                <form [formGroup]="contactForm" (ngSubmit)="onFormSubmit(contactForm)">
                    <div class="form-floating">
                        <h3>Contactez nous</h3>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" formControlName="nom" ngModel name="nom" id="nom" placeholder="Nom" [ngClass]="{ 'is-invalid': submitted && f.nom.errors }">
                                    <label for="nom">Nom*</label>
                                    <div class="invalid-feedback">
                                      Vous devez renseigner votre nom.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" formControlName="prenom" ngModel name="prenom" id="prenom" placeholder="Prénom" [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }">
                                    <label for="prenom">Prénom*</label>
                                    <div class="invalid-feedback">
                                      Vous devez renseigner votre prénom.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control" formControlName="email" ngModel name="email" id="email" placeholder="E-mail" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <label for="email">E-mail*</label>
                                    <div class="invalid-feedback">
                                      Vous devez renseigner votre email.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" formControlName="telephone" ngModel name="telephone" id="telephone" placeholder="Téléphone" [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }">
                                    <label for="tel">Téléphone*</label>
                                    <div class="invalid-feedback">
                                      Vous devez renseigner votre téléphone.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-floating">
                            <textarea class="form-control" formControlName="message" placeholder="Leave a comment here" ngModel name="message" id="message" style="height: 100px" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                            <label for="message">Message*</label>
                            <div class="invalid-feedback">
                              Vous devez renseigner votre message.
                            </div>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" formControlName="check" type="checkbox" id="gridCheck" [ngClass]="{ 'is-invalid': submitted && f.check.errors }">
                          <label class="form-check-label text-informations" for="gridCheck">
                            En validant ce formulaire, j'accepte que mes données soient utilisées pour traiter ma demande. Vos coordonnées ne seront pas
                            utilisées dans le cadre d’une démarche téléphonique commerciale.*<br>
                          </label>
                          <div class="invalid-feedback">
                            Vous devez cocher la case pour valider.
                          </div>
                        </div>
                        <p class="text-informations">* Les champs marqués d’un astérisque sont obligatoires</p>
                        <p class="mt-3 text-informations">
                          Les informations que vous nous communiquez font l'objet d'un traitement par l’Association Le Patis Fraux, responsable de ce
                          traitement, sur la base de votre consentement afin de traiter votre demande et de vous communiquer les informations relatives
                          à celle-ci. Ces informations seront conservées pendant une durée n’excédant pas celle nécessaire aux fins pour lesquelles elles
                          sont traitées. Elles pourront être accessibles par les personnes habilitées de l’Association Le Patis Fraux et s’il y a lieu,
                          ses partenaires et ses sous-traitants éventuels.<br>
                          Vous ne devez pas utiliser ce formulaire pour communiquer des informations relatives à votre santé, vos origines raciales,
                          vos opinions politiques, philosophiques ou religieuses ou votre appartenance syndicale.
                        </p>
                        <div class="text-xxl-start text-center">
                            <button class="btn btn-demande mt-3 mb-3" type="submit">Envoyer</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
