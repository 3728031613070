export class Actu {
  id!: number;
  titre!: string;
  texte_vignette!: string;
  urlImage!: string;
  dateCreation!: { date: string };
  dateDebut!: { date : string};
  dateFin!: { date : string};
  slug !: string;
  contenu !: string;
  style !: string;
}
