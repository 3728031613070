<div class="img-container" [style.background-image]="'url(\''+linkBandeau+'\')'">
    <!--<img src="{{ linkBandeau }}">-->
    <div class="text">
        <h1 class="etablissement__h1">{{ titreBandeau }}</h1>
        <div class="breadcrumb__span">{{ breadcrumb }}</div>
    </div>
    <img class="ovale" src="./../../assets/symbole-ovale.png">
    <img class="ovale2" src="./../../assets/symbole-ovale.png">
</div>

