import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {ContactComponent} from './_structure/contact/contact.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {EtablissementComponent} from "./etablissement/etablissement.component";
import {PoleMedicoSocialComponent} from "./pole-medico-social/pole-medico-social.component";
import {PoleSanitaireComponent} from "./pole-sanitaire/pole-sanitaire.component";
import {PatientsUsagerComponent} from "./patients-usager/patients-usager.component";
import {FormationPrestationComponent} from "./formation-prestation/formation-prestation.component";
import {ActualitesComponent} from "./actualites/actualites.component";
import {NousRejoindreComponent} from "./nous-rejoindre/nous-rejoindre.component";
import {NousRejoindreDetailComponent} from "./nous-rejoindre-detail/nous-rejoindre-detail.component";
import {PartenaireComponent} from './partenaire/partenaire.component';
import {HabitatInclusifComponent} from './habitat-inclusif/habitat-inclusif.component';
import {MaisonSportSanteComponent} from './maison-sport-sante/maison-sport-sante.component';
import {ServiceReadaptationComponent} from './pole-medico-social/service-readaptation/service-readaptation.component';
import {
  ServiceAccueilTemporaireComponent
} from './pole-medico-social/service-accueil-temporaire/service-accueil-temporaire.component';
import {FoyerHebergementComponent} from './pole-medico-social/foyer-hebergement/foyer-hebergement.component';
import {
  ServiceAccompagnementComponent
} from './pole-medico-social/service-accompagnement/service-accompagnement.component';
import {ActualitesDetailComponent} from './actualites-detail/actualites-detail.component';
import {ProgrammeReadaptationComponent} from './pole-sanitaire/programme-readaptation/programme-readaptation.component';
import {ProgrammeRestaurationComponent} from './pole-sanitaire/programme-restauration/programme-restauration.component';
import {FaireDonComponent} from './faire-don/faire-don.component';
import {MentionsLegalesComponent} from './mentions-legales/mentions-legales.component';
import {PageConstruiteComponent} from "./page-construite/page-construite.component";
import { PolitiqueDeConfidentialiteComponent } from './politique-de-confidentialite/politique-de-confidentialite.component';

const routes: Routes = [
  {path: 'contactez-nous', component: ContactComponent},
  {path: 'etablissement', component: EtablissementComponent},
  {path: 'pole-medico-social', component: PoleMedicoSocialComponent},
  {
    path: 'pole-medico-social/service-readaptation-professionnelle', component: PageConstruiteComponent, data: {
      infoBandeau: {
        image: "./../../assets/bandeau-srp.jpg",
        titre: 'Service de réadaptation professionnelle',
        breadcrumb: 'ACCUEIL > Pôle médico-social > Service de réadaptation professionnelle'
      },
      pageId: 1
    }
  },
  {
    path: 'pole-medico-social/service-accompagnement-adultes-hadicapes', component: PageConstruiteComponent, data: {
      infoBandeau: {
        image: "./../../assets/bandeau-samsah.jpg",
        titre: 'Service d\'accompagnement médico-social pour adultes handicapés',
        breadcrumb: 'ACCUEIL > Pôle médico-social > Service d\'accompagnement médico-cosial pour adultes handicapés'
      },
      pageId: 13
    }
  },
  {
    path: 'pole-medico-social/service-accueil-temporaire', component: PageConstruiteComponent, data: {
      infoBandeau: {
        image: "./../../assets/bandeau-site-web-temphau.jpg",
        titre: 'Service accueil temporaire - TempHau',
        breadcrumb: 'ACCUEIL > Pôle médico-social > Service accueil temporaire - TempHau'
      },
      pageId: 2
    }
  },
  {
    path: 'pole-medico-social/foyer-hebergement-adultes-handicapes', component: PageConstruiteComponent, data: {
      infoBandeau: {
        image: "./../../assets/bandeau-fhah2.jpg",
        titre: 'foyer d\'hébergement pour adultes handicapés',
        breadcrumb: 'ACCUEIL > Pôle médico-social > Foyer d\'hébergement pour adultes handicapés'
      },
      pageId: 3
    }
  },
  {
    path: 'pole-medico-social/service-accompagnement-vie-sociale', component: PageConstruiteComponent, data: {
      infoBandeau: {
        image: "./../../assets/bandeau-savs.jpg",
        titre: 'Service d\'accompagnement à la vie sociale',
        breadcrumb: 'ACCUEIL > Pôle médico-social > Service d\'accompagnement à la vie sociale'
      },
      pageId: 4
    }
  },
  {path: 'pole-sanitaire', component: PoleSanitaireComponent},
  {
    path: 'pole-sanitaire/programme-readaptation', component: PageConstruiteComponent, data: {
      infoBandeau: {
        image: "./../../assets/bandeau-pole-sanitaire.png",
        titre: 'RÉHABILITATION PROFESSIONNELLE',
        breadcrumb: 'ACCUEIL > Pôle sanitaire > Réhabilitation Professionnelle'
      },
      pageId: 5
    }
  },
  {
    path: 'pole-sanitaire/programme-restauration', component: PageConstruiteComponent, data: {
      infoBandeau: {
        image: "./../../assets/bandeau-RFR.jpg",
        titre: 'PROGRAMME RESTAURATION FONCTIONNELLE DU RACHIS',
        breadcrumb: 'ACCUEIL > Pôle sanitaire > Programme de restauration fonctionnelle du rachis'
      },
      pageId: 6
    }
  },
  {
    path: 'habitat-inclusif', component: PageConstruiteComponent, data: {
      infoBandeau: {
        image: './../../assets/bandeau-site-web-hi.jpg',
        titre: 'Habitat inclusif',
        breadcrumb: 'ACCUEIL > Habitat inclusif'
      },
      pageId: 7
    }
  },
  {
    path: 'maison-sport-sante', component: PageConstruiteComponent, data: {
      infoBandeau: {
        image: './../../assets/bandeau-mss.jpg',
        titre: 'Maison Sport Santé',
        breadcrumb: 'ACCUEIL > Maison sport santé'
      },
      pageId: 8
    }
  },
  {
    path: 'activite-physique-adaptee', component: PageConstruiteComponent, data: {
      infoBandeau: {
        image: './../../assets/bandeau-etablissement.png',
        titre: 'Activité physique adaptée',
        breadcrumb: 'ACCUEIL > Activité physique adaptée'
      },
      pageId: 10
    }
  },
  {
    path: 'salle-snoezelen', component: PageConstruiteComponent, data: {
      infoBandeau: {
        image: './../../assets/bandeau-etablissement.png',
        titre: 'Salle Snoezelen',
        breadcrumb: 'ACCUEIL > Salle Snoezelen'
      },
      pageId: 11
    }
  },
  {
    path: 'mise-en-situation-professionnelle', component: PageConstruiteComponent, data: {
      infoBandeau: {
        image: './../../assets/bandeau-mises-en-situation-professionnelles.jpg',
        titre: 'Mise en situation professionnelle',
        breadcrumb: 'ACCUEIL > Mise en situation professionnelle'
      },
      pageId: 12
    }
  },
  {
    path: 'pole-medico-social/ehpad-clos-orriere', component: PageConstruiteComponent, data: {
      infoBandeau: {
        image: "./../../assets/bandeau-ehpad.jpg",
        titre: 'EHPAD DU CLOS D’ORRIÈRE',
        breadcrumb: 'ACCUEIL > Pôle médico-social > EHPAD DU CLOS D’ORRIÈRE'
      },
      pageId: 14
    }
  },
  {path: 'patient-usager', component: PatientsUsagerComponent},
  {path: 'formation-prestation', component: FormationPrestationComponent},
  {path: 'actualites', component: ActualitesComponent},
  {path: 'actualites/:slug', component: ActualitesDetailComponent},
  {path: 'nous-rejoindre', component: NousRejoindreComponent},
  {path: 'nous-rejoindre/:slug', component: NousRejoindreDetailComponent},
  {path: 'partenaires', component: PartenaireComponent},
  {path: 'faire-un-don', component: FaireDonComponent},
  {path: 'mentions-legales', component: MentionsLegalesComponent},
  {path: 'politique-de-confidentialite', component: PolitiqueDeConfidentialiteComponent},
  {path: '', component: HomeComponent},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule
{
}
