import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Offre } from '../_models/offre';


@Injectable({
  providedIn: 'root'
})

export class OffreService {
  constructor(private http: HttpClient) {
  }

  getOffresDisplayed() {
    return this.http.get<Offre[]>(`${environment.api}/api/offres/display`);
  }
  getLatestOffre() {
    return this.http.get<Offre[]>(`${environment.api}/api/offres/last`);
  }

  getOneOffre(id: number) {
    return this.http.get<Offre>(`${environment.api}/api/offres/`+id);
  }

  getOneOffreBySlug(slug: string) {
    return this.http.get<Offre>(`${environment.api}/api/offres/slug/`+slug);
  }

}
